import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import ActionSelector from './ActionSelector'
import ContactModalForm from '../contact/ContactModalForm'
import ErrorContent from './ErrorContent'
import LabelList from '../LabelList'
import Modal from './../Modal'
import AllRowSelect from './AllRowSelect'
import ResourceRow from './ResourceRow'
import ShareResult from './ShareResult'
import utils from '../utils/utils'

const ShareDocumentModalForm = ({
  agreements,
  availableAgreements = [],
  cancel,
  contact,
  contacts = [],
  documents,
  isRequestReponse,
  onSave,
  partner,
  planAllowsLabels,
  requestSlug,
}) => {
  // DEFAULT STATES

  const { register, handleSubmit, errors, formState, setValue, getValues } = useForm(formOptions)
  const [action, setAction] = useState('bind_document_with_agreement')
  const [error, setError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [newContact, setNewContact] = useState(false)
  const [sentData, setSentData] = useState(false)
  const [signer, setSigner] = useState(contact ? contact.id : null)
  const [step, setStep] = useState('actionSelect')
  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedLabelIds, setSelectedLabelIds] = useState(['all'])

  // DEFAULT VARS

  let cancelButton
  let contactModalForm = false
  let content
  let saveButton

  const agreementList = []
  const availableAgreementList = []
  const contactList = []
  const documentList = []
  const formOptions = {}
  const selectedContactArray = Array.isArray(selectedContacts) ? selectedContacts : (selectedContacts ? [selectedContacts] : [])

  // FUNCTIONS

  const contactAdded = (contact) => {
    contacts.push(contact)
    setNewContact('refresh')
    const newContactIds = action == 'send_agreement' ? [contact.id.toString()] : selectedContactArray.concat(contact.id.toString())
    setSelectedContacts(newContactIds)
    setValue('contact_ids', newContactIds)

    if (action == 'send_agreement' || (action == 'bind_document_with_agreement' && selectedAgreementsToSign() && !signer)) {
      setSigner(contact.id)
    }
  }

  const formIsValid = () => {
    if (action == 'send_agreement') {
      return hasSelectedAgreements() && hasSelectedContacts() && validatePresenceOfSigner()
    } else if (action == 'send_document') {
      return hasSelectedDocuments() && hasSelectedContacts()
    } else {
      return hasSelectedAgreements() && hasSelectedDocuments() && hasSelectedContacts() && validatePresenceOfSigner()
    }
  }

  const shouldSubmitForm = () => {
    if (isRequestReponse) {
      if (action == 'send_document') {
        return step == 'documents'
      } else {
        return step == 'agreements'
      }
    } else {
      return step == 'contacts'
    }
  }

  const hasContacts = () => {
    return contact !== undefined || contacts.length > 0
  }

  const hasSigner = () => {
    return signer
  }

  const hasSelectedContacts = () => {
    if (contact) {
      return contact.id
    }

    return selectedContactArray.length > 0
  }

  const hasSelectedDocuments = () => {
    const values = getValues()
    return 'document_resource_ids' in values && values['document_resource_ids'].length > 0
  }

  const hasSelectedAgreements = () => {
    const values = getValues()
    return 'agreement_resource_ids' in values && values['agreement_resource_ids'].length > 0
  }

  const selectedAgreementsToSign = () => {
    const values = getValues()

    if (!('agreement_resource_ids' in values)) {
      return false
    }

    const agreement_resource_ids_values = values['agreement_resource_ids']
    const agreement_resource_ids = Array.isArray(agreement_resource_ids_values) ? agreement_resource_ids_values : [agreement_resource_ids_values]

    return agreements.filter((agreement) => {
      return agreement_resource_ids.indexOf(agreement.id.toString()) > -1 && agreement.status == 'not_shared'
    }).length > 0
  }

  const validatePresenceOfSigner = () => {
    return selectedAgreementsToSign() ? hasSigner() : true
  }

  const stepIsValid = () => {
    if (step == 'actionSelect') {
      return action !== ''
    }
    if (step == 'documents') {
      return hasSelectedDocuments()
    }
    if (step == 'agreements') {
      return hasSelectedAgreements()
    }
    if (step == 'contacts') {
      return hasSelectedContacts() && (action == 'send_document' || validatePresenceOfSigner())
    }
  }

  const hasDataToProgress = () => {
    if (step === 'actionSelect') {
      return (documents.length > 0 || availableAgreements.length > 0) && hasContacts()
    }

    if (action == 'send_agreement') {
      return agreements.length > 0 && hasContacts()
    } else if (action == 'send_document') {
      return documents.length > 0 && hasContacts()
    } else {
      return documents.length > 0 && agreements.length > 0 && hasContacts()
    }
  }

  const checkboxSelected = (field) => {
    const values = getValues()
    const hasError = field in values && (values[field].length == 0 || !values[field])
    const error = hasError ? errorMessage() : ''
    setError(error)

    if (field === 'contact_ids') {
      setSelectedContacts(values[field])

      const noSignatureRequired = !selectedAgreementsToSign()

      if (action != 'send_document') {
        if (!values[field] || values[field].length == 0 || noSignatureRequired) {
          setSigner(null)
          if (noSignatureRequired) {
            return
          }
        }
        const selectedContactIds = Array.isArray(values[field]) ? values[field] : (values[field] ? [values[field]] : [])
        if (selectedContactIds && selectedContactIds.length > 0 && (!signer || selectedContactIds.indexOf(signer) == -1)) {
          setSigner(selectedContactIds[selectedContactIds.length - 1])
        }
      }
    }
  }

  const errorMessage = () => {
    if (step == 'contacts' && action == 'send_agreement') {
      return `Select exactly one contact to share`
    } else if (step != 'documents') {
      return `Select at least one ${step.slice(0, -1)} to share`
    }
  }

  const popupTitle = () => {
    switch (step) {
      case 'actionSelect':
        if (documentList.length == 0 && availableAgreements.length == 0) {
          return 'No Files'
        }
        if (!hasContacts()) {
          return 'No Contacts'
        }
        return 'Send Envelope'
      case 'documents':
        return 'Choose Documents'
      case 'agreements':
        return 'Choose Agreements'
      case 'contacts':
        if (action == 'send_document') {
          return 'Choose Recipient'
        } else {
          return 'Choose Signer'
        }
      case 'done':
        return 'Sent!'
      case 'error':
        return 'Error'
      default:
        return ''
    }
  }

  const popupContent = () => {
    switch (step) {
      case 'actionSelect':
        return <p>Choose what you'll send to {partner.name}:</p>
      case 'documents':
        return !planAllowsLabels ? <p>Next, choose documents to send to {partner.name}:</p> : null
      case 'agreements':
        return <p>Next, choose agreements to send to {partner.name}:</p>
      case 'contacts':
        if (action == 'send_document') {
          return <p>Next, choose who should receive those documents:</p>
        } else {
          if (selectedAgreementsToSign()) {
            return <p>Next, choose who should sign these agreements:</p>
          } else {
            return <p>Next, choose who should receive those documents:</p>
          }
        }
      default:
        return false
    }
  }

  const nextStep = () => {
    if (action == 'send_agreement') {
      if (step == 'actionSelect') {
        return 'agreements'
      }
      if (step == 'agreements') {
        return 'contacts'
      }
    }
    else if (action == 'send_document') {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        if (isRequestReponse) {
          return 'done'
        } else {
          return 'contacts'
        }
      }
    } else {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        return 'agreements'
      }
      if (step == 'agreements') {
        return 'contacts'
      }
    }
    if (step == 'contacts') {
      return 'done'
    }
    if (step == 'done') {
      return
    }
  }

  const previousStep = () => {
    if (action == 'send_agreement') {
      if (step == 'agreements') {
        return 'actionSelect'
      }
      if (step == 'contacts') {
        return 'agreements'
      }
    } else if (action == 'send_document') {
      if (step == 'documents') {
        return 'actionSelect'
      }
      if (step == 'contacts') {
        return 'documents'
      }
    } else {
      if (step == 'documents') {
        return 'actionSelect'
      }
      if (step == 'agreements') {
        return 'documents'
      }
      if (step == 'contacts') {
        return 'agreements'
      }
    }
  }

  const stepForward = () => {
    if (stepIsValid()) {
      setStep(nextStep())
      setError(false)
    } else {
      setError(errorMessage())
    }
  }

  const shouldDisplayBackButton = () => {
    return step !== 'actionSelect' && step !== 'done' && step !== 'error'
  }

  const errorContent = (title, content) => (
    <div className="column">
      <ErrorContent content={content} title={title} />
    </div>
  )

  const stepBack = () => {
    if (step == 'actionSelect') {
      return
    }

    resetStepSelection(step)
    setStep(previousStep())
    setError(false)
  }

  const resetStepSelection = (step) => {
    if (step == 'documents') {
      setValue('document_resource_ids', [])
    }
    if (step == 'agreements') {
      setValue('agreement_resource_ids', [])
    }
    if (step == 'contacts') {
      setSigner(null)
      setSelectedContacts([])
      setValue('contact_ids', [])
    }
  }

  const fieldsBasedOnCurrentAction = () => {
    switch (action) {
      case 'send_agreement':
        return ["agreement_resource_ids", "contact_ids"]
      case 'send_document':
        return ["document_resource_ids", "contact_ids"]
      case 'bind_document_with_agreement':
        return ["agreement_resource_ids", "document_resource_ids", "contact_ids"]
      default:
        return []
    }
  }

  const shareDocuments = (data) => {
    setIsSending(true)

    if (!formIsValid()) {
      setIsSending(false)
      return
    }

    const values = getValues()
    const fields = fieldsBasedOnCurrentAction()

    for (var i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (field in values && (values[field].length == 0 || !values[field])) {
        setError(errorMessage())
        return
      }
      // force array values
      data[field] = Array.isArray(data[field]) ? data[field] : [data[field]]
    }

    data['contact_ids'] = isRequestReponse ? [contact.id] : data['contact_ids']

    if (signer) {
      data['signer_id'] = signer
    }

    fetch(`/companies/${partner.slug}`, {
      method: 'PUT',
      body: JSON.stringify({ share: data }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then((res) => {
      if (isRequestReponse) {
        return fetch(`/requests/${requestSlug}`, {
          method: 'PUT',
          body: JSON.stringify({ share: data }),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': utils.csrfToken()
          }
        })
      } else {
        return res
      }
    }).then((res) => {
      setIsSending(false)
      setSentData(data)
      setStep('done')
    }).catch(err => {
      setStep('error')
    })
  }

  // LOGIC BEGINS

  // Reset signer if no agreements to sign and no contact, otherwise contact is signer
  if (!contact && signer && !selectedAgreementsToSign()) {
    setSigner(null)
  }

  // Refresh contact list after contact has been added
  if (newContact === 'refresh') {
    setTimeout(() => {
      setNewContact(false)
    }, 100)
  }

  // build document list
  const sortedDocuments = documents.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }).entries()
  for (const [index, document] of sortedDocuments) {
    if (selectedLabelIds[0] !== 'all') {
      if (document.label_ids.filter(label_id => selectedLabelIds.indexOf(label_id) > -1).length === 0) {
        continue
      }
    }

    documentList.push(
      <ResourceRow
        key={index}
        checked={isRequestReponse}
        resource={document}
        resourceType="document"
        name="document_resource_ids"
        onResourceSelected={documentId => checkboxSelected('document_resource_ids')}
        reference={register()} />
    )
  }

  // build agreement list (already shared or not)
  const sortedAgreements = agreements.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }).entries()
  for (const [index, agreement] of sortedAgreements) {
    agreementList.push(
      <ResourceRow
        key={index}
        resource={agreement}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={agreementId => checkboxSelected('agreement_resource_ids')}
        reference={register()} />
    )
  }

  // build agreement list (not shared)
  const sortedAvailableAgreements = availableAgreements.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }).entries()
  for (const [index, agreement] of sortedAvailableAgreements) {
    availableAgreementList.push(
      <ResourceRow
        key={index}
        checked={isRequestReponse}
        resource={agreement}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={agreementId => checkboxSelected('agreement_resource_ids')}
        reference={register()} />
    )
  }

  // build contact list
  const sortedContacts = contacts.sort((a, b) => {
    const nameA = `${a.first_name.toUpperCase()} ${a.last_name.toUpperCase()}`
    const nameB = `${b.first_name.toUpperCase()} ${b.last_name.toUpperCase()}`
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }).entries()
  for (const [index, contact] of sortedContacts) {
    contactList.push(
      <ResourceRow
        key={index}
        contactCanBeSigner={selectedAgreementsToSign() && action == 'bind_document_with_agreement' && selectedContactArray.indexOf(contact.id.toString()) > -1}
        contactIsSigner={signer && signer.toString() == contact.id.toString()}
        signerSelected={(contactId) => setSigner(contactId)}
        disabled={hasSelectedContacts() && action == 'send_agreement' ? selectedContactArray.indexOf(contact.id.toString()) == -1 : false}
        resource={contact}
        resourceType="contact"
        name="contact_ids"
        onResourceSelected={(contactId) => checkboxSelected('contact_ids')}
        reference={register()} />
    )
  }

  // add "Add New Contact" to bottom of contact list
  contactList.push(
    <li key="newContactAction" style={{ padding: '20px 0px 0px 11px' }}>
      <a style={{ fontWeight: 'bold' }} onClick={() => setNewContact(true)}>
        <div className="columns">
          <div className="column fixed-55-width">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#0075e0" fillRule="evenodd" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
            </svg>
          </div>
          <div className="column">Add New Contact</div>
        </div>
      </a>
    </li>
  )

  // display contact form
  if (newContact === true) {
    const alreadyExistingEmails = contacts.map(contact => contact.email)
    contactModalForm = <ContactModalForm
      alreadyExistingEmails={alreadyExistingEmails}
      agreementSlug={partner.slug}
      cancelAction={() => setNewContact(false)}
      company={partner}
      onSave={(contact) => contactAdded(contact)}
      title={"Add Contact"} />
  }

  const shareResult = sentData && <div style={{ visibility: step == 'done' ? 'visible' : 'hidden', position: step == 'done' ? 'relative' : 'absolute', top: step == 'done' ? 'auto' : '-1000px' }}>
    <ShareResult
      agreements={agreements.filter((agreement) => (sentData['agreement_resource_ids'] && sentData['agreement_resource_ids'].indexOf(agreement.id.toString()) > -1))}
      contacts={(contact ? [contact] : false) || contacts.filter((contact) => (sentData['contact_ids'] && sentData['contact_ids'].indexOf(contact.id.toString()) > -1))}
      documents={documents.filter((document) => (sentData['document_resource_ids'] && sentData['document_resource_ids'].indexOf(document.id.toString()) > -1))} />
  </div>

  const documentListDom = <ul className="documents"
    style={{
      visibility: step == 'documents' ? 'visible' : 'hidden',
      position: step == 'documents' ? 'relative' : 'absolute',
      top: step == 'documents' ? 'auto' : '-1000px'
    }}>
    {step == 'documents' && documentList.length > 0 && <AllRowSelect
      checked={(getValues()['document_resource_ids'] ? (Array.isArray(getValues()['document_resource_ids']) ? getValues()['document_resource_ids'] : [getValues()['document_resource_ids']]) : []).length === documents.map(d => d.id.toString()).length && (getValues()['document_resource_ids'] ? (Array.isArray(getValues()['document_resource_ids']) ? getValues()['document_resource_ids'] : [getValues()['document_resource_ids']]) : []).every((value, index) => value === documents.map(d => d.id.toString())[index])}
      indeterminate={(getValues()['document_resource_ids'] || []).length > 0}
      onChange={() => { checkboxSelected('document_resource_ids') }}
      resourceList={documentList}
      resourceType="document" />}
    {documentList}
  </ul>

  const agreementListDom = <ul className="agreements"
    style={{ visibility: step == 'agreements' ? 'visible' : 'hidden', position: step == 'agreements' ? 'relative' : 'absolute', top: step == 'agreements' ? 'auto' : '-1000px' }}>
    {action == 'send_agreement' ? availableAgreementList : agreementList}
  </ul>

  const contactListDom = <ul className="contacts"
    style={{ visibility: step == 'contacts' ? 'visible' : 'hidden', position: step == 'contacts' ? 'relative' : 'absolute', top: step == 'contacts' ? 'auto' : '-1000px' }}>
    {contactList}
  </ul>

  const actionSelect = hasDataToProgress() ? <ActionSelector agreements={agreements}
    documents={documents}
    key="actionSelector"
    innerStyle={{ visibility: step == 'actionSelect' ? 'visible' : 'hidden', position: step == 'actionSelect' ? 'relative' : 'absolute', top: step == 'actionSelect' ? 'auto' : '-1000px' }}
    isRequestReponse={isRequestReponse}
    onChange={(action) => setAction(action)}
    partner={partner}
    availableAgreements={availableAgreements} /> : undefined

  if (shouldSubmitForm()) {
    saveButton = <input className={`button is-info ${formIsValid() ? '' : 'disabled'}`}
      disabled={isSending}
      draggable="false"
      type="submit"
      value="Send Now" />
  } else {
    if ((documents.length > 0 && hasContacts()) || (agreements.length > 0 && hasContacts())) {
      saveButton = <a className={`button is-info ${stepIsValid() ? '' : 'disabled'}`}
        draggable="false"
        onClick={() => stepForward()}>Next</a>
    } else {
      saveButton = false
    }
  }

  if (step === 'done') {
    cancelButton = true
    saveButton = <a className="button is-info"
      draggable="false"
      onClick={() => onSave()}>Done</a>
  } else if (step === 'error') {
    cancelButton = true
    saveButton = <a className="button is-info"
      draggable="false"
      onClick={() => onSave()}>Close</a>
    content = errorContent('Could not send this envelope', 'Please try again later...')
  } else if (hasDataToProgress()) {
    cancelButton = <a className="button is-white" onClick={cancel}>Cancel</a>
    content = popupContent()
  } else if (documentList.length == 0 && availableAgreements.length == 0) {
    cancelButton = true
    saveButton = <a className="button" onClick={cancel}>Close</a>
    content = errorContent('Add Documents or Agreements', `Add at least one Document or Agreement to begin sharing with ${partner.name}.`)
  } else if (!hasContacts()) {
    cancelButton = <a className="button" onClick={cancel}>Close</a>
    content = errorContent('Add Contacts', `Add at least one Contact to begin sharing with ${partner.name}.`)
  }

  if (step == 'contacts') {
    cancelButton = <a className="button is-white" onClick={cancel}>Cancel</a>
  }

  const errorElement = error ? <label className="error">{error}</label> : null

  const backButton = shouldDisplayBackButton() ? <a className="button"
    draggable="false"
    onClick={() => stepBack()}
    style={{ paddingLeft: '12px', position: 'absolute', left: '40px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path fill="#F4F5F5" d="M-633-21H807v1470H-633z" />
        <path fill="#FFF" d="M-32-21h839v60H-32z" />
        <g transform="translate(-12 -9)">
          <rect width="89" height="35" x=".5" y=".5" stroke="#CED0D2" rx="6" />
          <path fill="#424C53" d="M27 17.25h-9.128l4.193-4.192L21 12l-6 6 6 6 1.057-1.057-4.185-4.193H27z" />
        </g>
      </g>
    </svg>
    <span style={{ paddingLeft: '10px' }}>Back</span>
  </a> : null

  const labelList = planAllowsLabels && step === 'documents' ? <LabelList
    addAllOption={true}
    onLabelSelectionChange={labels => setSelectedLabelIds(labels)}
    selectedLabelIds={selectedLabelIds}
    withBorderBottom={true} /> : null

  const modalForm = <form onSubmit={handleSubmit(shareDocuments)} style={{ display: (newContact === true ? 'none' : 'block') }}>
    <Modal
      cancelAction={step === 'done' ? onSave : cancel}
      cancelButton={cancelButton}
      confirmButton={saveButton}
      deleteButton={backButton}
      title={popupTitle()}
      modalCardStyle={{ height: '600px', minWidth: '750px', overflowX: 'hidden' }}
      modalCardBodyStyle={{ padding: 0, overflowX: 'hidden' }}>
      <div className="content">
        <div className="columns">
          <div className="column document-list">
            {content}
            {actionSelect}
            {labelList}
            {documentListDom}
            {agreementListDom}
            {contactListDom}
            {shareResult}
            {errorElement && <p style={{ color: '#d92022', fontSize: '12px', fontWeight: 600 }} className="error">{errorElement}</p>}
          </div>
        </div>
      </div>
    </Modal>
  </form>

  return <>
    {modalForm}
    {contactModalForm}
  </>
}

export default ShareDocumentModalForm
